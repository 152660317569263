import { MarketCountryCode } from '@backmarket/http-api'

export type AboutUsData = {
  bCorpLogo: {
    filepath: string
    url: string
  }
  pressLogos: Array<{
    filepath: string
    url: string
    width: number
    height: number
    alt: string
  }>
  hasImpactArticle: boolean
}

export const aboutUsData: Record<MarketCountryCode, AboutUsData> = {
  [MarketCountryCode.FR]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_FR.png',
      url: 'https://www.backmarket.fr/fr-fr/c/news/bcorp-status',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/les-echos.svg',
        url: 'https://capitalfinance.lesechos.fr/analyses/dossiers/thibaud-hug-de-larauze-ceo-de-back-market-le-business-au-service-de-leconomie-circulaire-1775506',
        width: 229,
        height: 80,
        alt: 'Les Echos, Capital finance',
      },
      {
        filepath: '/img/about-us/press/sud-ouest.svg',
        url: 'https://www.sudouest.fr/appareils-numeriques-comment-le-reconditionnement-reussit-a-contrer-l-irreparabilite-programmee-13274974.php',
        width: 99,
        height: 80,
        alt: 'Sud Ouest',
      },
      {
        filepath: '/img/about-us/press/forbes.svg',
        url: 'https://www.forbes.fr/technologie/back-market-la-startup-francaise-la-plus-valorisee-souhaite-creer-une-economie-circulaire-pour-la-technologie/',
        width: 229,
        height: 80,
        alt: 'Forbes',
      },
    ],
    hasImpactArticle: true,
  },
  [MarketCountryCode.AT]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_DE.png',
      url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/spiegel.svg',
        url: 'https://www.spiegel.de/netzwelt/gadgets/refurbished-handys-wer-gebraucht-kauft-kauft-besser-a-48292730-ea53-4a00-b0a6-7bb4da456cf5',
        width: 199,
        height: 80,
        alt: 'Spiegel',
      },
      {
        filepath: '/img/about-us/press/stern.svg',
        url: 'https://www.stern.de/digital/smartphones/refurbished-smartphones-kaufen--warentest-empfiehlt-diese-anbieter-33220852.html',
        width: 178,
        height: 80,
        alt: 'Stern',
      },
      {
        filepath: '/img/about-us/press/utopia.svg',
        url: 'https://utopia.de/ratgeber/test-zu-refurbished-smartphones-stiftung-warentest/',
        width: 136,
        height: 80,
        alt: 'Utopia',
      },
    ],
    hasImpactArticle: true,
  },
  [MarketCountryCode.BE]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_FR.png',
      url: 'https://www.backmarket.be/fr-be/c/events/bcorp-status',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/les-echos.svg',
        url: 'https://capitalfinance.lesechos.fr/analyses/dossiers/thibaud-hug-de-larauze-ceo-de-back-market-le-business-au-service-de-leconomie-circulaire-1775506',
        width: 229,
        height: 80,
        alt: 'Les Echos, Capital finance',
      },
      {
        filepath: '/img/about-us/press/sud-ouest.svg',
        url: 'https://www.sudouest.fr/appareils-numeriques-comment-le-reconditionnement-reussit-a-contrer-l-irreparabilite-programmee-13274974.php',
        width: 99,
        height: 80,
        alt: 'Sud Ouest',
      },
      {
        filepath: '/img/about-us/press/forbes.svg',
        url: 'https://www.forbes.fr/technologie/back-market-la-startup-francaise-la-plus-valorisee-souhaite-creer-une-economie-circulaire-pour-la-technologie/',
        width: 229,
        height: 80,
        alt: 'Forbes',
      },
    ],
    hasImpactArticle: true,
  },
  [MarketCountryCode.DE]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_DE.png',
      url: 'https://www.backmarket.de/de-de/c/news/bcorp-status',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/spiegel.svg',
        url: 'https://www.spiegel.de/netzwelt/gadgets/refurbished-handys-wer-gebraucht-kauft-kauft-besser-a-48292730-ea53-4a00-b0a6-7bb4da456cf5',
        width: 199,
        height: 80,
        alt: 'Spiegel',
      },
      {
        filepath: '/img/about-us/press/stern.svg',
        url: 'https://www.stern.de/digital/smartphones/refurbished-smartphones-kaufen--warentest-empfiehlt-diese-anbieter-33220852.html',
        width: 178,
        height: 80,
        alt: 'Stern',
      },
      {
        filepath: '/img/about-us/press/utopia.svg',
        url: 'https://utopia.de/ratgeber/test-zu-refurbished-smartphones-stiftung-warentest/',
        width: 136,
        height: 80,
        alt: 'Utopia',
      },
    ],
    hasImpactArticle: true,
  },
  [MarketCountryCode.ES]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_ES.png',
      url: 'https://www.backmarket.es/es-es/c/news/b-corp-back-market',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/el-pais.svg',
        url: 'https://elpais.com/tecnologia/2021-12-03/por-que-deberias-regalar-tecnologia-reacondicionada-estas-navidades.html',
        width: 149,
        height: 80,
        alt: 'El País',
      },
      {
        filepath: '/img/about-us/press/la-vanguardia.svg',
        url: 'https://www.lavanguardia.com/economia/20230226/8782411/moviles-reacondicionar-ventas-empresas-nuevos-usados.html',
        width: 221,
        height: 80,
        alt: 'La Vanguardia',
      },
      {
        filepath: '/img/about-us/press/expansion.svg',
        url: 'https://www.expansion.com/economia-digital/innovacion/2023/01/28/63d25a86e5fdeafd0c8b45e1.html',
        width: 160,
        height: 80,
        alt: 'Expansión',
      },
    ],
    hasImpactArticle: true,
  },
  [MarketCountryCode.FI]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_EN.png',
      url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/wirecutter.svg',
        url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
        width: 187,
        height: 80,
        alt: 'New York Times, Wirecutter',
      },
      {
        filepath: '/img/about-us/press/wsj.svg',
        url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
        width: 160,
        height: 80,
        alt: 'Wall Street Journal',
      },
      {
        filepath: '/img/about-us/press/good-morning-america.svg',
        url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
        width: 157,
        height: 80,
        alt: 'Good Morning America',
      },
    ],
    hasImpactArticle: false,
  },
  [MarketCountryCode.GB]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_EN.png',
      url: 'https://www.backmarket.co.uk/en-gb/c/news/bcorp-status',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/mail-online.svg',
        url: 'https://www.dailymail.co.uk/sciencetech/article-12086337/Im-tech-repair-expert-mistakes-killing-machine.html',
        width: 188,
        height: 80,
        alt: 'Daily mail, Mail online',
      },
      {
        filepath: '/img/about-us/press/the-times.svg',
        url: 'https://www.thetimes.co.uk/article/laptop-and-gadget-recycler-to-open-uk-market-bkm0vxjsx',
        width: 165,
        height: 80,
        alt: 'The Times',
      },
      {
        filepath: '/img/about-us/press/independent.svg',
        url: 'https://www.independent.co.uk/extras/indybest/gadgets-tech/phones-accessories/best-refurbished-iphone-deals-b2318404.html',
        width: 179,
        height: 80,
        alt: 'Independent',
      },
    ],
    hasImpactArticle: true,
  },
  [MarketCountryCode.GR]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_EN.png',
      url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/wirecutter.svg',
        url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
        width: 187,
        height: 80,
        alt: 'New York Times, Wirecutter',
      },
      {
        filepath: '/img/about-us/press/wsj.svg',
        url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
        width: 160,
        height: 80,
        alt: 'Wall Street Journal',
      },
      {
        filepath: '/img/about-us/press/good-morning-america.svg',
        url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
        width: 157,
        height: 80,
        alt: 'Good Morning America',
      },
    ],
    hasImpactArticle: false,
  },
  [MarketCountryCode.IE]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_EN.png',
      url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/mail-online.svg',
        url: 'https://www.dailymail.co.uk/sciencetech/article-12086337/Im-tech-repair-expert-mistakes-killing-machine.html',
        width: 188,
        height: 80,
        alt: 'Daily mail, Mail online',
      },
      {
        filepath: '/img/about-us/press/the-times.svg',
        url: 'https://www.thetimes.co.uk/article/laptop-and-gadget-recycler-to-open-uk-market-bkm0vxjsx',
        width: 165,
        height: 80,
        alt: 'The Times',
      },
      {
        filepath: '/img/about-us/press/independent.svg',
        url: 'https://www.independent.co.uk/extras/indybest/gadgets-tech/phones-accessories/best-refurbished-iphone-deals-b2318404.html',
        width: 179,
        height: 80,
        alt: 'Independent',
      },
    ],
    hasImpactArticle: false,
  },
  [MarketCountryCode.IT]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_IT.png',
      url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/wirecutter.svg',
        url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
        width: 187,
        height: 80,
        alt: 'New York Times, Wirecutter',
      },
      {
        filepath: '/img/about-us/press/wsj.svg',
        url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
        width: 160,
        height: 80,
        alt: 'Wall Street Journal',
      },
      {
        filepath: '/img/about-us/press/good-morning-america.svg',
        url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
        width: 157,
        height: 80,
        alt: 'Good Morning America',
      },
    ],
    hasImpactArticle: false,
  },
  [MarketCountryCode.NL]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_EN.png',
      url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/wirecutter.svg',
        url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
        width: 187,
        height: 80,
        alt: 'New York Times, Wirecutter',
      },
      {
        filepath: '/img/about-us/press/wsj.svg',
        url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
        width: 160,
        height: 80,
        alt: 'Wall Street Journal',
      },
      {
        filepath: '/img/about-us/press/good-morning-america.svg',
        url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
        width: 157,
        height: 80,
        alt: 'Good Morning America',
      },
    ],
    hasImpactArticle: false,
  },
  [MarketCountryCode.PT]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_PT.png',
      url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/wirecutter.svg',
        url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
        width: 187,
        height: 80,
        alt: 'New York Times, Wirecutter',
      },
      {
        filepath: '/img/about-us/press/wsj.svg',
        url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
        width: 160,
        height: 80,
        alt: 'Wall Street Journal',
      },
      {
        filepath: '/img/about-us/press/good-morning-america.svg',
        url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
        width: 157,
        height: 80,
        alt: 'Good Morning America',
      },
    ],
    hasImpactArticle: false,
  },
  [MarketCountryCode.SE]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_EN.png',
      url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/wirecutter.svg',
        url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
        width: 187,
        height: 80,
        alt: 'New York Times, Wirecutter',
      },
      {
        filepath: '/img/about-us/press/wsj.svg',
        url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
        width: 160,
        height: 80,
        alt: 'Wall Street Journal',
      },
      {
        filepath: '/img/about-us/press/good-morning-america.svg',
        url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
        width: 157,
        height: 80,
        alt: 'Good Morning America',
      },
    ],
    hasImpactArticle: false,
  },
  [MarketCountryCode.SK]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_EN.png',
      url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/wirecutter.svg',
        url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
        width: 187,
        height: 80,
        alt: 'New York Times, Wirecutter',
      },
      {
        filepath: '/img/about-us/press/wsj.svg',
        url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
        width: 160,
        height: 80,
        alt: 'Wall Street Journal',
      },
      {
        filepath: '/img/about-us/press/good-morning-america.svg',
        url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
        width: 157,
        height: 80,
        alt: 'Good Morning America',
      },
    ],
    hasImpactArticle: false,
  },
  [MarketCountryCode.US]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_EN.png',
      url: 'https://www.backmarket.com/en-us/c/news/bcorp-status',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/wirecutter.svg',
        url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
        width: 187,
        height: 80,
        alt: 'New York Times, Wirecutter',
      },
      {
        filepath: '/img/about-us/press/wsj.svg',
        url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
        width: 160,
        height: 80,
        alt: 'Wall Street Journal',
      },
      {
        filepath: '/img/about-us/press/good-morning-america.svg',
        url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
        width: 157,
        height: 80,
        alt: 'Good Morning America',
      },
    ],
    hasImpactArticle: true,
  },
  [MarketCountryCode.JP]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_EN.png',
      url: 'https://www.backmarket.co.jp/ja-jp/c/news/bcorp-status',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/wirecutter.svg',
        url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
        width: 187,
        height: 80,
        alt: 'New York Times, Wirecutter',
      },
      {
        filepath: '/img/about-us/press/wsj.svg',
        url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
        width: 160,
        height: 80,
        alt: 'Wall Street Journal',
      },
      {
        filepath: '/img/about-us/press/good-morning-america.svg',
        url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
        width: 157,
        height: 80,
        alt: 'Good Morning America',
      },
    ],
    hasImpactArticle: false,
  },
  [MarketCountryCode.AU]: {
    bCorpLogo: {
      filepath: '/img/about-us/b-corp/b-corp_EN.png',
      url: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/back-market/',
    },
    pressLogos: [
      {
        filepath: '/img/about-us/press/wirecutter.svg',
        url: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
        width: 187,
        height: 80,
        alt: 'New York Times, Wirecutter',
      },
      {
        filepath: '/img/about-us/press/wsj.svg',
        url: 'https://www.youtube.com/watch?v=WHqxBlFuIXA',
        width: 160,
        height: 80,
        alt: 'Wall Street Journal',
      },
      {
        filepath: '/img/about-us/press/good-morning-america.svg',
        url: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
        width: 157,
        height: 80,
        alt: 'Good Morning America',
      },
    ],
    hasImpactArticle: false,
  },
}
