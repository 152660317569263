<template>
  <div>
    <div class="bg-surface-default-hi text-static-default-low mood-tangaroa">
      <div :class="sectionClasses">
        <div :class="subSectionClasses">
          <h1 class="punchline mb-24">
            {{ i18n(translations.aboutUsBannerTitle) }}
          </h1>
          <h2 class="heading-2">
            {{ i18n(translations.aboutUsBannerDescription) }}
          </h2>
        </div>
      </div>
    </div>
    <div>
      <div :class="sectionClasses">
        <div :class="subSectionClasses">
          <div :class="sectionMarginClasses">
            <p class="punchline mb-24">
              {{ i18n(translations.aboutUsBlock1Title) }}
            </p>
            <p class="heading-2">
              {{ i18n(translations.aboutUsBlock1Description) }}
            </p>
          </div>
          <div :class="sectionMarginClasses">
            <div class="flex items-center" data-test="press-section">
              <RevLink
                v-for="(logo, index) in aboutUsData.pressLogos"
                :key="index"
                class="mx-4 md:mx-16"
                target="_blank"
                :to="logo.url"
              >
                <RevIllustration
                  :alt="logo.alt"
                  :height="logo.height"
                  :src="logo.filepath"
                  :width="logo.width"
                />
              </RevLink>
            </div>
          </div>
          <RevDivider :class="sectionMarginClasses" />
          <div :class="sectionMarginClasses">
            <p class="punchline mb-24">
              {{ i18n(translations.aboutUsBlock2Title) }}
            </p>

            <FormattedMessage
              class="heading-2"
              :definition="translations.aboutUsBlock2Description"
            >
              <template #link>
                <RevLink :to="verifiedRefurbishLink">
                  {{ i18n(translations.aboutUsBlock2Link) }}
                </RevLink>
              </template>
            </FormattedMessage>
          </div>
          <RevDivider :class="sectionMarginClasses" />
          <div :class="sectionMarginClasses">
            <p class="punchline mb-24">
              {{ i18n(translations.aboutUsBlock3Title) }}
            </p>
            <p class="heading-2">
              {{ i18n(translations.aboutUsBlock3Description) }}

              <RevLink
                v-if="aboutUsData.hasImpactArticle"
                :to="impactArticleLink"
              >
                {{ i18n(translations.aboutUsBlock3Link) }}
              </RevLink>
            </p>
          </div>
          <div data-test="bcorp-section">
            <RevIllustration
              alt=""
              class="mx-auto mb-32 md:mb-72"
              :height="300"
              :src="aboutUsData.bCorpLogo.filepath"
              :width="600"
            />
            <div class="px-0 md:px-72">
              <RevButton
                full-width="always"
                target="_blank"
                :to="{
                  href: aboutUsData.bCorpLogo.url,
                  type: 'external',
                }"
                variant="secondary"
              >
                {{ i18n(translations.aboutUsBCorpButtonLabel) }}
              </RevButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RevContainer class="bg-surface-default-mid py-56">
      <RevCard class="p-32">
        <RevIllustration
          alt=""
          class="mb-16"
          :height="70"
          src="/img/merchants/hotline.svg"
          :width="80"
        />
        <p class="heading-2 mb-12">
          {{ i18n(translations.aboutUsInfoBlockTitle) }}
        </p>
        <p class="body-1 mb-32">
          {{ i18n(translations.aboutUsInfoBlockDescription) }}
        </p>
        <RevButton
          :to="resolveLocalizedRoute({ name: HELP_CENTER.HOME })"
          variant="secondary"
        >
          {{ i18n(translations.aboutUsInfoBlockButtonLabel) }}
        </RevButton>
      </RevCard>
    </RevContainer>
  </div>
</template>

<script lang="ts" setup>
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { tw } from '@backmarket/utils/string/tw'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevContainer } from '@ds/components/Container'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import { useBackmarketHead } from '~/composables/useBackmarketHead'
import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { CMS } from '~/scopes/cms/routes-names'
import { HELP_CENTER } from '~/scopes/help-center/routes'

import translations from './AboutUs.translations'
import { ABOUT_US_EXTERNAL_PAGE_VERIFIED_REFURBISHED } from './routes.constants'
import { useAboutUsData } from './useAboutUsData'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const sectionClasses = tw`md:mx-auto md:max-w-[34rem]`
const sectionMarginClasses = tw`mb-72 md:mb-[7.5rem]`
const subSectionClasses = tw`px-56 py-72 md:px-0 md:py-[7.5rem]`

const ABOUT_US_META_IMAGE = '/img/about-us-meta-image.png'

const aboutUsData = useAboutUsData()

const impactArticleLink = resolveLocalizedRoute({
  name: CMS.ARTICLE,
  params: {
    pageName: 'impact-of-refurbished-on-environment',
    category: 'news',
  },
})

const verifiedRefurbishLink = resolveLocalizedRoute({
  name: ABOUT_US_EXTERNAL_PAGE_VERIFIED_REFURBISHED,
})

useBackmarketHead({
  image: ABOUT_US_META_IMAGE,
})
</script>
