export default {
  aboutUsBannerTitle: {
    id: 'about_us_banner_headline',
    defaultMessage: 'Hi, we’re Back Market.',
  },
  aboutUsBannerDescription: {
    id: 'about_us_banner_description',
    defaultMessage:
      'We’re here to help make tech reliable, affordable, and better than new.',
  },
  aboutUsBlock1Title: {
    id: 'about_us_block_1_title',
    defaultMessage: 'A global marketplace for reborn tech',
  },
  aboutUsBlock1Description: {
    id: 'about_us_block_1_description',
    defaultMessage:
      'We’re the leading global marketplace for refurbished devices. Our rigorous vetting process means that only the best refurbishers can sell on our platform.',
  },
  aboutUsBlock2Title: {
    id: 'about_us_block_2_title',
    defaultMessage: 'Premium refurbished tech',
  },
  aboutUsBlock2Description: {
    id: 'about_us_block_2_description',
    defaultMessage:
      'We work hand-in-hand with our sellers to guarantee that {link, html} according to industry standards. All for up to 70% less than brand new.',
  },
  aboutUsBlock2Link: {
    id: 'about_us_block_2_link',
    defaultMessage:
      'every device has been tested and restored to perfect working condition',
  },
  aboutUsBlock3Title: {
    id: 'about_us_block_3_title',
    defaultMessage: 'Better for the planet',
  },
  aboutUsBlock3Description: {
    id: 'about_us_block_3_description',
    defaultMessage:
      'A refurbished smartphone uses 91.3% less raw materials, 86.4% less water, generates 89% less e-waste, and puts 91.6% less carbon emissions into our atmosphere compared to brand new.',
  },
  aboutUsBlock3Link: {
    id: 'about_us_block_3_link',
    defaultMessage: 'And that’s just on average.',
  },
  aboutUsBCorpButtonLabel: {
    id: 'about_us_bcorp_button',
    defaultMessage: 'Discover more',
  },
  aboutUsInfoBlockTitle: {
    id: 'about_us_info_block_title',
    defaultMessage: 'Here for you',
  },
  aboutUsInfoBlockDescription: {
    id: 'about_us_info_block_description',
    defaultMessage:
      'Our in-house customer service team is here to help 6 days a week. You’ll talk to a real person that works for Back Market. Every time.',
  },
  aboutUsInfoBlockButtonLabel: {
    id: 'about_us_info_block_button',
    defaultMessage: 'Contact us',
  },
}
